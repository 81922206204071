import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/404.png')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              // boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
              // backgroundColor: "#f40",
              color: "white",
              padding: "1rem",
            }}
          >
            404 Not Found
          </h1>
        </div>
  </Layout>
);

export default NotFoundPage;
